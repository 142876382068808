.mfp-bg {
    --cc-bg: #ffffff;
    --cc-text: #292929;
    --cc-btn-primary-bg: #292929;
    --cc-btn-primary-text: var(--cc-bg);
    --cc-btn-primary-hover-bg: #f9ad8d;
    --cc-btn-secondary-bg: #ffffff;
    --cc-btn-secondary-text: var(--cc-text);
    --cc-btn-secondary-hover-bg: var(--cc-btn-primary-bg);
    --cc-toggle-bg-off: var(--cc-bg);
    --cc-toggle-bg-on: var(--cc-bg);
    --cc-toggle-bg-readonly: var(--cc-bg);
    --cc-toggle-knob-bg: #292929;
    --cc-toggle-knob-icon-color: #000000;
    --cc-block-text: var(--cc-text);
    --cc-cookie-category-block-bg: #eaf2f8;
    --cc-cookie-category-block-bg-hover: #eaf2f8;
    --cc-section-border: #f1f3f5;
    --cc-cookie-table-border: #e1e7f3;
    --cc-overlay-bg: rgba(0, 0, 0, 0.5);
    --cc-webkit-scrollbar-bg: #ebeff9;
    --cc-webkit-scrollbar-bg-hover: #3859d0;
}

/* General */

.mfp-bg .cc_div {
    font: 500 15px/22px 'Lato', sans-serif;
}

/* Primary and Secondary Btns */

.mfp-bg #c-bns button:first-child,
.mfp-bg #s-bns button:first-child {
    width: 219px;
    max-width: 100%;
    height: 56px;
    font-weight: 700;
    font-size: 17px;
}

.mfp-bg #c-bns button + button,
.mfp-bg #s-cnt button + button {
    border: 1px solid #292929;
    font-weight: 700;
    font-size: 17px;
    transition: background-color 0.25s ease, color 0.25s ease !important;
    height: 56px;
    max-width: 100%;
}

.mfp-bg #c-bns button + button:hover,
.mfp-bg #s-cnt button + button:hover {
    color: #ffffff;
}

.mfp-bg .cc_div #c-bns {
    display: inline-flex;
}

@media screen and (max-width: 688px) {
    .mfp-bg .cc_div #c-bns {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        gap: 15px;
    }

    .mfp-bg #cm.bar #c-bns {
        max-width: 100%;
    }

    .mfp-bg #s-bns {
        height: 10em !important;
        gap: 10px;
        display: grid;
    }

    .mfp-bg #c-bns button:first-child,
    .mfp-bg #s-bns button:first-child,
    .mfp-bg #c-bns button + button,
    .mfp-bg #s-cnt button + button {
        font-size: 15px;
        width: 100%;
        margin-top: 0;
        padding: 0;
    }
}

/* Banner */

.mfp-bg #cc_div #cm {
    background: rgba(255, 255, 255, 0.96);
    border-top: 8px solid #292929;
}

/* ------ Heading ------- */

.mfp-bg #s-ttl,
.mfp-bg #c-ttl {
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    font-family: 'Epilogue';
}

@media screen and (max-width: 688px) {
    .mfp-bg #s-ttl {
        text-align: left;
    }
}
/* ------ Content ------- */

.mfp-bg #cm.bar #c-inr {
    max-width: 1165px;
    text-align: center;
}

.mfp-bg #c-txt {
    text-align: center;
}

.mfp-bg #c-txt .link {
    text-decoration-line: underline;
    font-weight: 700;
    cursor: pointer;
}

/* ------ */

/* Settings Modal */

/* ------ Modal ------- */

.mfp-bg #s-inr {
    max-width: 776px;
}

.mfp-bg #c-s-in {
    max-height: 700px;
}

.mfp-bg #s-bl {
    padding: 0 1.8em 0 1.3em;
}

@media screen and (max-width: 688px) {
    .mfp-bg #c-s-in {
        height: calc(100% - 2.5em);
        max-height: 100%;
        top: 20px;
    }

    .mfp-bg #s-inr {
        max-width: calc(100% - 1.5em);
        margin: 0 auto;
        border: 2px solid #000000;
        border-radius: 10px;
    }

    .mfp-bg #s-bl {
        padding-bottom: 1.6em;
    }
}

/* ------ Inner Boxes ------- */

.mfp-bg #s-bl .b-acc {
    display: block;
    max-height: inherit;
}

.mfp-bg #s-cnt .b-bn .b-tl.exp {
    cursor: auto;
}

.mfp-bg .cc_div .act .b-bn .exp:before,
.mfp-bg .cc_div .b-bn .exp:before {
    content: none;
}
@media screen and (max-width: 688px) {
    .mfp-bg #s-cnt .b-bn .b-tl {
        max-width: 80%;
    }
}

/* ------ Heading ------- */

.mfp-bg #s-hdr {
    border: none;
    font-family: 'Epilogue';
}

/* ------ Close Btn Modal ------- */

.mfp-bg #s-c-bnc {
    position: absolute;
    top: 15px;
    right: 20px;
}

.mfp-bg #s-c-bn:after,
.mfp-bg #s-c-bn:before {
    width: 3.5px;
    height: 1em;
}

.mfp-bg #s-c-bn:hover {
    background: transparent;
}

/* ------ Content ------- */

.mfp-bg #s-cnt .b-bn .b-tl {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    padding: 25px;
    font-family: 'Epilogue';
}

.mfp-bg #s-cnt .p {
    font-size: 14px;
    line-height: 25px;
}

.mfp-bg #s-bl .b-acc .p {
    padding: 0 25px 25px;
}

.mfp-bg #s-bl .b-acc ul {
    padding-left: 30px;
    margin-top: 15px;
}

.mfp-bg #s-bl .b-acc strong {
    font-weight: 700;
}

/* ------ Radio Btns ------- */

.mfp-bg .cc_div .b-tg .c-tg:after {
    width: 26px;
    height: 26px;
    left: 3px;
    box-shadow: none;
}

.mfp-bg .cc_div .b-tg,
.mfp-bg .cc_div .b-tg .c-tg,
.mfp-bg .cc_div .b-tg .c-tgl,
.mfp-bg .cc_div span.t-lb {
    width: 56px;
    height: 30px;
    border-radius: 65px;
}

.mfp-bg .cc_div .b-tg .c-tgl:disabled ~ .c-tg:after {
    background: #8a8a8a !important;
}

.mfp-bg .cc_div .b-tg .c-tgl:checked ~ .c-tg:after {
    background: #ff6c4a;
    left: -3px;
}

.mfp-bg .cc_div .on-i:before,
.mfp-bg .cc_div .off-i:after,
.mfp-bg .cc_div .off-i:before {
    top: 9px;
}

/* ------ Footer Btns ------- */

.mfp-bg #s-bns {
    text-align: center;
    border: none;
    height: 5.75em;
}

.mfp-bg #s-cnt button + button {
    float: unset;
}

/* ------ */
