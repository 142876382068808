.logo {
    background-image: url(/src/image/logo-domainer-desktop.svg);
    background-size: contain;
    width: 191px;
    height: 36px;
    display: block;
    font-size: 0;
    color: transparent;
}

.logo-footer {
    background-image: url(/src/image/logo-white.svg);
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
    max-width: 246px;
    width: 100%;
    height: 47px;
    display: block;
    font-size: 0;
    color: transparent;
}

/* Dotted background */

.dots-bg {
    background-image: radial-gradient(#ffebe3 6%, transparent 6%);
    background-position: 22px 21px;
    background-size: 44px 44px;
    background-color: #f9f7f7;
}

.footer-bg {
    background-color: #1d2124;
}

.dark-bg {
    background-color: var(--heading-color);
}

.light-bg {
    background-color: var(--secondary-color);
}

.lighter-bg {
    background-color: var(--background-color-light);
}

.grey-bg {
    background-color: #e5e3e3;
}

.white-bg {
    background-color: #ffffff;
}

/* Old Design pages */

.light-blue-bg {
    background-color: #e8eff5;
}

.bg-blue {
    background: #eaf2f8;
}

.bg-orange {
    background: var(--bg-orange-box);
}

.bg-dark-orange {
    background: #f37d62;
}

.bg-orange-light {
    background: var(--bg-orange-light);
}

.bg-dark {
    background: #343434;
}

.bg-grey {
    background: #efefef;
}

/* ---------- */

.underline-heading,
.meet-ian-bg,
.grouped-dots-bg,
.six-dots-bg,
.favicon-bg {
    position: relative;
}

/* H1 underline stroke */

.underline-heading::after {
    content: '';
    background-image: url(/src/image/illustration-underline_heading.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: block;
    position: relative;
    width: 100%;
    height: 40px;
}

/* Texts sections' backgrounds */

.meet-ian-bg::after {
    content: '';
    background-image: url(/src/image/meet-ian-garner.webp);
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    position: absolute;
    height: 100%;
    width: 45%;
    top: 0;
    right: 0;
}

@media only screen and (max-width: 867px) {
    .meet-ian-bg::after {
        bottom: -100px;
        width: 352px;
        height: 264px;
        top: unset;
    }
}
@media only screen and (max-width: 1067px) {
    .meet-ian-bg::after {
        background-position: center;
    }
}

.eight-dots-bg::after {
    content: '';
    background-image: url(/src/image/eight-dots-bg.svg);
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    position: absolute;
    width: 110px;
    height: 41px;
    top: 40%;
    right: 0;
    z-index: 1;
}
@media only screen and (max-width: 1067px) {
    .eight-dots-bg::after {
        top: 0px;
    }
}

.favicon-bg::after {
    content: '';
    background-image: url(/src/image/illustration-favicon-logo.svg);
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    position: absolute;
    width: 450px;
    height: 688.09px;
    bottom: 0;
    right: 0;
    z-index: -1;
}

.casino-jp-page .favicon-bg::after {
    background-image: url(/src/image/illustration-favicon-logo-whole.svg);
    background-position: left;
    background-size: contain;
    right: unset;
    left: -50px;
    bottom: 100px;
    width: 550px;

}

@media only screen and (max-width: 1067px) {
    .favicon-bg::after, .casino-jp-page .favicon-bg::after {
        width: 395px;
        height: 309px;
    }
}

@media only screen and (max-width: 867px) {
    .favicon-bg::after{
        bottom: 50%;
    }

    .casino-jp-page .favicon-bg::after {
        content: none;
    }
}

.grouped-dots-bg::after {
    content: '';
    background-image: url(/src/image/grouped-dots-bg.svg);
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    width: 109.5px;
    height: 110px;
    position: absolute;
    right: -30px;
    bottom: 85%;
}

.six-dots-bg::before {
    content: '';
    background-image: url(/src/image/six-dots-bg.svg);
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    height: 110px;
    width: 41px;
    position: absolute;
    left: -20px;
    bottom: 30%;
    z-index: 1;
}

@media only screen and (max-width: 867px) {
    .grouped-dots-bg::after {
        bottom: 70%;
    }
}

@media only screen and (max-width: 1067px) {
    .grouped-dots-bg::after {
        background-size: 70px;
        right: -10px;
    }
    .six-dots-bg::before {
        background-size: 30px 70px;
    }
}

.articles-bg::after {
    content: '';
    background: var(--background-color-light);
    border-radius: 20px;
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 0;
}

@media only screen and (max-width: 867px) {
    .articles-bg::after {
        content: none;
    }
    .articles-wrapper::after {
        content: '';
        background: var(--background-color-light);
        border-radius: 25px;
        position: absolute;
        width: 100%;
        height: 100%;
        right: 0;
        top: 0;
        z-index: -1;
    }
}
