.card {
    display: block;
    box-sizing: content-box;
    padding: 30px;
    border-radius: 20px;
    position: relative;
    transition: all 0.25s;
}

.card .card-hover::after {
    content: '';
    position: absolute;
    height: 0;
    width: 70%;
    left: 50%;
    bottom: 20px;
    transform: translate(-50%);
    background: #121416;
    filter: blur(26.5px);
    border-radius: 51px;
    z-index: -1;
    transition: all 0.25s;
}

.card:hover .card-hover::after {
    height: 50%;
}

.card .arrow::after {
    content: url(/src/image/arrow_right.svg);
    font-size: inherit;
    position: relative;
    margin-left: -10px;
    transition: all 0.25s;
    z-index: -1;
}

.card:hover .arrow::after {
    filter: brightness(0%);
    margin-left: 10px;
    z-index: 1;
}
@media only screen and (max-width: 867px) {
    .card .card-hover::after {
        height: 50%;
    }

    .card .arrow::after {
        content: url(/src/image/arrow_right.svg);
        font-size: inherit;
        position: relative;
        transition: all 0.25s;
        filter: brightness(0%);
        margin-left: 10px;
        z-index: 1;
    }
}

.card.bordered {
    border: 1px solid #2a2e32;
}
