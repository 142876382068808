.btn,
.btn button,
.btn span {
    position: relative;
    width: max-content;
    border: none;
}

.btn a,
.btn button,
.btn span {
    display: block;
    font-family: var(--heading-fonts);
    font-weight: 700;
    text-align: center;
    font-size: 16px;
    border-radius: 50px;
    padding: 25px 38px;
    color: #ffffff;
    text-decoration: none;
    z-index: 1;
    position: relative;
    cursor: pointer;
}

.btn::after {
    content: '';
    height: 30%;
    width: 70%;
    filter: blur(26.5px);
    border-radius: 51px;
    position: absolute;
    left: 50%;
    bottom: -5%;
    transform: translate(-50%);
    transition: all 0.25s;
}

.shadow-black::after {
    background: #121416;
}

.shadow-orange::after {
    background: #d07864;
}

.btn:hover::after {
    height: 0;
}

.primary {
    background-color: var(--primary-color);
    transition: all 0.25s;
}

.primary:hover {
    background-color: #ff886d;
}

.secondary {
    background-color: var(--heading-color);
    transition: all 0.25s;
}

.secondary:hover {
    background-color: #3d4247;
}

.btn a span,
.btn button span {
    font-weight: 400;
    font-family: var(--body-fonts);
}

.btn a.arrow-right::after,
.btn button.arrow-right::after,
.btn span.arrow-right::after {
    content: url(/src/image/arrow_right.svg);
    font-size: inherit;
    position: relative;
    margin-left: 15px;
    transition: all 0.25s;
    right: 0px;
}

.btn:hover a.arrow-right::after,
.btn:hover button.arrow-right::after,
.btn:hover span.arrow-right::after {
    right: -10px;
}

.btn-header::after {
    content: none;
}

.btn-header a {
    padding: 18px 26px;
}

@media only screen and (max-width: 1024px) {
    .btn-header {
        display: none;
    }
}

@media only screen and (max-width: 867px) {
    .btn,
    .btn button,
    .btn span {
        width: 100%;
    }
    .btn a,
    .btn button,
    .btn span {
        font-size: 16px;
        padding-left: 0;
        padding-right: 0;
    }
}
