@media only screen and (min-width: 1025px) {
    .mobile-swiper .masonry.swiper-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: masonry;
        column-gap: 30px;
    }

    .mobile-swiper .masonry .swiper-slide:nth-of-type(odd) {
        padding-top: 40px;
    }
}

.mobile-swiper .masonry .slider-card {
    padding-top: 62px;
    padding-bottom: 69px;
}

.swiper-pagination {
    bottom: -80px;
}

.swiper-pagination-bullet-active {
    background: var(--primary-color) !important;
}

@media only screen and (max-width: 867px) {
    .swiper.mobile-swiper {
        height: 380px;
        width: 100vw;
        margin-left: -20px;
    }

    .mobile-swiper .masonry.swiper-wrapper {
        width: 80vw;
    }
}

/* Old design */

.items .item {
    background: #fff;
    border-radius: 10px;
    border-bottom: 0px solid transparent;
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    padding: 10px;
    height: 92px;
    position: relative;
    box-shadow: 0px 0px 29px rgba(12, 30, 97, 0.1);
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
    transition: padding-bottom 0.25s, border-bottom 0.25s;
    text-align: center;
}

@media only screen and (min-width: 1024px) {
    .items .item {
        flex-basis: calc(50% - 13px);
        margin-top: 20px;
    }
}

@media only screen and (min-width: 768px) {
    .items .item {
        height: 108px;
    }
}

@media only screen and (max-width: 867px) {
    .items {
        margin-top: 20px;
        padding: 26px 20px;
    }
}

.slider-nav {
    opacity: 0;
    height: 0;
    list-style: none;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    margin: 12px auto 0px;
    padding: 20px 0 0 0;
    width: 50px;
}

/* Arrowed Slider */

.featured-premium-domains-section .slider-wrapper {
    overflow: hidden;
}

.featured-premium-domains-section .slider-wrapper .items {
    width: 18000px;
}

@media only screen and (min-width: 1025px) {
    .items {
        display: flex;
        flex-flow: row wrap;
    }
}

@media only screen and (min-width: 768px) {
    .items {
        margin-top: 50px;
    }
}

.featured-premium-domains-section .slider-wrapper .slide {
    display: grid;
    font-weight: bold;
    justify-content: center;
    transition: all 0.35s ease-in-out;
}

.slider-nav-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px auto 0 auto;
}

.nav-arrow.left {
    background-image: url(/src/image/arrow_right.svg);
    transform: rotate(180deg);
    margin-right: 20px;
}

.nav-arrow.right {
    background-image: url(/src/image/arrow_right.svg);
    margin-left: 20px;
}

.nav-arrow {
    background: #1c1c1c;
    border-radius: 5px;
    width: 37px;
    height: 38px;
    background-repeat: no-repeat;
    background-size: 19.5px;
    background-position: center center;
    cursor: pointer;
}

.featured-premium-domains-section .slider-nav {
    opacity: 1;
    height: auto;
    width: 200px;
    margin: 0 10px;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.slider-nav li {
    width: 9px;
    height: 9px;
    border-radius: 9px;
    background-color: #e8f0f7;
    transition: background-color 0.2s linear;
    cursor: pointer;
}

.slider-nav li.active {
    background-color: #ff7657;
}

.slider-nav.mob-visible,
.slider-nav .nav-mob-visible {
    display: none;
}

@media (max-width: 1024px) {
    .slider-nav.mob-visible,
    .slider-nav .nav-mob-visible {
        display: block;
    }
}

@media (max-width: 1024px) {
    .slider-wrapper {
        overflow: hidden;
    }
    .nav-arrow {
        display: none;
    }
    .slider-nav-box {
        margin-top: 20px;
    }
    .featured .slider-nav {
        width: 200px;
    }
    .slider-nav {
        opacity: 1;
        height: auto;
    }
    .slider-nav .mob-visible {
        display: block;
    }
    .project-listing .slider-wrapper .items {
        width: 4000px;
    }

    .slider-wrapper .slide,
    .featured .slider-wrapper .slide {
        display: inline-block !important;
        width: 75vw !important;
        padding: 5px;
        transition: margin 0.2s linear;
    }
    .featured-premium-domains-section .slider-wrapper {
        margin: 0 -20px;
    }

    .featured-premium-domains-section .slider-wrapper .slide {
        width: 85vw !important;
    }
    .featured-item + .featured-item {
        margin-top: 20px;
    }
    .domain-investment-page .slide {
        width: 82vw !important;
    }
}

/* Projects slider inner pages */

.project-listing {
    padding: 0 60px;
    margin-bottom: 150px;
    position: relative;
}
.domain-investment-page .project-listing {
    margin-bottom: 0px;
}
@media only screen and (min-width: 1025px) {
    .project-listing:after {
        content: '';
        position: absolute;
        bottom: -60px;
        left: 0;
        right: 0;
        margin: 0 auto;
        background: #c8c8c8;
        border-radius: 5px;
        height: 1px;
        width: 91%;
    }
    .domain-investment-page .project-listing:after {
        content: none;
    }
    .project-listing .items {
        justify-content: space-between;
    }
}
.project-listing .items .item {
    flex-basis: 30.33%;
    border: 1.5px solid #000000;
    background: rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    border-radius: 5px;
    height: 235px;
    padding: 20px;
    position: relative;
    box-shadow: none;
    transition: border-color 0.25s linear;
}
.project-listing .items .item:before {
    content: '';
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center center;
    width: 94%;
    height: 94%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
    filter: blur(3px);
    -webkit-filter: blur(3px);
}
.domain-investment-page .project-listing .items .item:before {
    content: none;
}
.domain-investment-page .project-listing .items .item:hover {
    border-color: inherit;
}
.project-listing .items .item:hover {
    border-color: #ff7657;
}
.project-listing .items .item.gamesnft:before {
    background: url(/src/image/gamesnft.webp) !important;
}
.project-listing .items .item.gamesmetaversecom:before {
    background: url(/src/image/gamesmetaverse.webp) !important;
}
.project-listing .items .item.nftsecurity:before {
    background: url(/src/image/nftsecurity.webp) !important;
}

.project-listing .items .item.database-icon {
    background: url(/src/image/database_icon.svg) 50% 20%/61px no-repeat;
}

.project-listing .items .item.document-icon {
    background: url(/src/image/document_icon.svg) 50% 20%/54px no-repeat;
}
.project-listing .items .item.rating-icon {
    background: url(/src/image/rating_icon.svg) 50% 20%/56px no-repeat;
}

.domain-investment-page .project-listing .items {
    margin-top: 20px;
}
.domain-investment-page .project-listing .items .item p {
    margin-top: 70px;
}
@media only screen and (max-width: 1024px) {
    .project-listing {
        padding: 0;
        margin: 0 -20px;
    }
    .project-listing .items {
        display: flex;
    }
    .domain-investment-page .project-listing .items {
        margin-top: 0;
        padding: 0px 10px 20px;
    }
}

/* ----------- */
