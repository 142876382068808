/* Partnership Box */

.partnership-box {
    width: 300px;
    max-width: 100%;
    margin: 0 auto;
    position: relative;
}

.partnership-box::after {
    content: '';
    width: 70%;
    height: 46px;
    margin: auto;
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    border-radius: 51px;
    background: #dececa;
    filter: blur(26.5px);
}

.partnership-inner{
    background: #fff;
    border-radius: 61px;
    z-index: 1;
    display: flex;
    align-items: center;
    position: relative;
}

.partnership-title {
    color: #fff;
    font-size: 15px;
    text-align: center;
    line-height: 18px;
    font-weight: 900;
    border-radius: 61px;
    padding: 10px 0;
    background: #d8be8c;
    flex-basis: 40%;
    margin: 3px;
}

.partnership-title span{
    display: block;
    font-weight: 400;
}

.partnership-image{
    line-height: 0;
}

.partnership-image img{
    max-width: 100%;
}
