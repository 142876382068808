/* Nav Socials */

.footer .socials a {
    background-position: 50%;
    background-repeat: no-repeat;
    color: transparent;
    display: block;
    font-size: 0;
    height: 30px;
    width: 30px;
}

.footer .socials a:hover,
.nav-visible .site-nav > ul.social a:hover {
    filter: brightness(0) invert(1);
}
.nav-visible .site-nav > ul.social {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
}
.nav-visible .site-nav > ul.social {
    gap: 15px;
}
.nav-visible .site-nav > ul.social > li {
    margin: 0;
}
.nav-visible .site-nav > ul.social a {
    display: block;
    width: 30px;
    height: 30px;
    font-size: 0;
    color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}
.footer .socials a.facebook,
.nav-visible .social a.facebook {
    background-image: url(/src/image/facebook2.svg);
}
.footer .socials a.twitter,
.nav-visible .social a.twitter {
    background-image: url(/src/image/twitter2.svg);
}
.footer .socials a.youtube-social,
.nav-visible .social a.youtube-social {
    background-image: url(/src/image/youtube2.svg);
}
.footer .socials a.linkedin,
.nav-visible .social a.linkedin {
    background-image: url(/src/image/linkedin2.svg);
}
.footer .socials a.medium,
.nav-visible .social a.medium {
    background-image: url(/src/image/medium2.svg);
    width: 33px !important;
}
.footer .socials a.discord,
.nav-visible .social a.discord {
    background-image: url(/src/image/discord2.svg);
    width: 30px !important;
}
.footer .socials a.instagram,
.nav-visible .social a.instagram {
    background-image: url(/src/image/instagram_white.svg);
    width: 22px !important;
}
.footer .socials-wrapper {
    padding-left: 10px;
    margin-top: 30px;
}

@media only screen and (min-width: 1024px) {
    .footer .socials {
        order: 2;
        flex-basis: 30%;
    }
}

@media only screen and (min-width: 768px) {
    .footer .socials .title {
        margin-bottom: 30px;
    }
    .footer .socials ul {
        justify-content: flex-start;
    }
    .footer .socials li {
        flex-basis: 11.66%;
    }
    .footer .socials a {
        margin: 0;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .footer .socials {
        flex-basis: 55%;
    }

    .footer .socials-wrapper {
        padding-left: 0;
        margin-top: 0;
    }
}

@media only screen and (min-width: 600px) and (max-width: 867px) {
    .footer .socials li {
        flex-basis: 10.66%;
    }
}

@media only screen and (max-width: 867px) {
    .footer .socials {
        padding-bottom: 20px;
    }

    .footer .socials li:first-child {
        margin-left: 0;
    }
    .footer .socials-wrapper {
        padding-left: 0;
        margin-top: 0;
    }
}
