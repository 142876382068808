.above-title {
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.04em;
    color: #50575f;
    text-transform: uppercase;
}

@media only screen and (max-width: 867px) {
    .mobile-h-title {
        display: none;
    }
    .mobile-above-title,
    .mobile-title {
        text-align: left;
    }
    .mobile-title {
        margin: 10px 0 25px;
    }
    .mobile-above-title[above-title]::before {
        content: attr(above-title);
        text-align: left;
    }
    .mobile-title[title]::before {
        content: attr(title);
        text-transform: none;
        text-align: left;
    }
}

.text-s32 {
    font-size: 32px;
    line-height: 50px;
}

.text-s22 {
    font-size: 22px;
    line-height: 32px;
}

.text-light {
    color: var(--background-color-light);
}

.text-dark {
    color: var(--heading-color);
}

.text-orange {
    color: var(--primary-color);
}

.text-white {
    color: #ffffff;
}

.text-black {
    color: #000000;
}

.text-center {
    text-align: center;
}

.text-underline {
    text-decoration: underline;
    text-underline-offset: 4px;
}

.text-italic{
    font-style: italic;
}

@media only screen and (max-width: 868px) {
    .text-s32 {
        font-size: 24px;
        line-height: 32px; 
    }
}

/* Stars Bullets */
.block .paragraph ul.star {
    list-style: none;
}
.block .paragraph ul.star > li {
    position: relative;
    padding-left: 50px;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    color: #2f2f2f;
}
.block .paragraph ul.star li + li,
ol.bolded li + li {
    margin-top: 30px;
}
.block .paragraph ul.star li:before {
    content: '';
    width: 32px;
    height: 32px;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 6px;
    background: #eaf2f8;
}
.block .paragraph ul.star li:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    background-image: url(/src/image/star.svg);
    width: 20px;
    height: 20px;
    background-size: 15px;
    background-position: center;
    background-repeat: no-repeat;
}

@media only screen and (max-width: 868px) {
    .block .paragraph ul.star > li {
        padding-left: 10px;
    }
    .block .paragraph ul.star li:before {
        left: -38px;
    }
    .block .paragraph ul.star li:after {
        left: -32px;
    }
}

/* Bolded Buletts Numbers */

ol.bolded {
    margin: 30px 0;
    list-style-type: decimal;
    padding-left: 2em;
}
ol.bolded li {
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    color: #292929;
}

/* Orange Bullets */

.block .bullets > li {
    font-weight: 500;
    font-size: 16px !important;
    line-height: 30px !important;
}
.block .bullets > li + li {
    margin-top: 30px;
}

.block .bullets.orange > li::marker {
    color: #f37d62;
    font-size: 1.4em;
}
/* Numbered sections */

.numbered {
    list-style-type: none;
    counter-reset: css-counter 0;
}

.numbered > div p.heading {
    counter-increment: css-counter 1;
}

.numbered > div p.heading:before {
    content: counter(css-counter) '. ';
    font-size: 32px;
    line-height: 50px;
    color: #ff6c4a;
}

/* ----------- */

.paragraph-small {
    font-size: 15px;
}

.stats-wrapper .stat:first-child {
    position: relative;
}

.stats-wrapper .stat:first-child:after {
    content: '';
    background: #dbd9d9;
    width: 1px;
    height: 55px;
    display: block;
    position: absolute;
    right: 13px;
    top: 40px;
}

@media only screen and (max-width: 867px) {
    .stats-wrapper .stat:first-child:after {
        width: 55px;
        height: 1px;
        right: unset;
        left: 0;
        bottom: -15px;
        top: unset;
    }
}

.stats-wrapper .stat-number {
    font-weight: 900;
    font-size: 32px;
    line-height: 52px;
    color: #6588a1;
}

.stats-wrapper .stat-text {
    font-weight: 400;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 29px;
    color: #6588a1;
}

.labels {
    background: #292929;
    border-radius: 38px;
    color: #fff;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: normal;
    line-height: 20px;
    max-width: calc(100% - 16px);
    overflow-x: hidden;
    padding: 6px 20px;
    text-overflow: ellipsis;
    text-transform: none;
    white-space: nowrap;
}

.link-as-label {
    color: #ffffff;
    font-weight: 700;
    background: rgba(41, 41, 41, 0.8);
    border-radius: 50px;
    padding: 8px 15px;
}

@media only screen and (max-width: 1067px) {
    .link-as-label {
        font-size: 14px;
        padding: 4px 8px;
    }
}

/* Contact */

.select-contact-label {
    font-family: 'Epilogue';
    font-weight: 900;
    font-size: 20px;
    line-height: 20px;
    color: var(--heading-color);
    position: relative;
    text-indent: 22px;
}
.select-contact-label::before,
.select-contact-label::after {
    content: '';
    position: absolute;
    left: 0;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    background: #ffffff;
}
.select-contact-label::before {
    border: 1px solid #c4c4c4;
    z-index: 1;
}

.active .select-contact-label::before {
    border: 1.5px solid #ffc8b1;
    background: #ff7657;
    width: 10px;
    height: 10px;
    left: 2.5px;
    top: 2.5px;
}

.active .select-contact-label::after {
    border: 1px solid #ffa580;
}

.active .select-contact-label {
    color: var(--primary-color);
}
