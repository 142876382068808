/* Paddings */

.pt-10 {
    padding-top: 10px;
}

.pt-30 {
    padding-top: 30px;
}

.pt-40 {
    padding-top: 40px;
}

.pt-100 {
    padding-top: 100px;
}

.pt-120 {
    padding-top: 120px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pb-30 {
    padding-bottom: 30px;
}

.pb-70 {
    padding-bottom: 70px;
}

.pb-120 {
    padding-bottom: 120px;
}

.pb-150 {
    padding-bottom: 150px;
}

@media only screen and (max-width: 867px) {
    .pb-150 {
        padding-bottom: 60px;
    }
    .pb-120 {
        padding-bottom: 80px;
    }
    .pb-70 {
        padding-bottom: 30px;
    }
    .pt-100 {
        padding-top: 30px;
    }
    .pt-120 {
        padding-top: 60px;
    }
}

/* Margins */

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-65 {
    margin-top: 65px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-70 {
    margin-bottom: 70px;
}

@media only screen and (max-width: 867px) {
    .mt-65 {
        margin-top: 30px;
    }
    .mb-70 {
        margin-bottom: 40px;
    }
}

/* Widths */

.wdht-50 {
    width: 50%;
}

.wdht-60 {
    width: 60%;
}

.wdht-70 {
    width: 70%;
}

.wdht-80 {
    width: 80%;
}

.wdht-90 {
    width: 90%;
}

@media only screen and (max-width: 867px) {
    .wdht-m-100 {
        width: 100%;
        max-width: 100% !important;
    }
}

/* Gaps */

.gap-10{
    gap: 10px;
}

.gap-20 {
    gap: 20px;
}

.gap-35 {
    gap: 35px;
}

.gap-36 {
    gap: 36px;
}

@media only screen and (max-width: 867px) {
    .gap-35 {
        gap: 20px;
    }
}