.section {
    display: flex;
    flex-basis: 100%;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-box-align: center;
    box-sizing: border-box;
    position: relative;
}

.section .reverse {
    order: 1;
}

.section-column {
    flex-direction: column;
}

.section.left {
    align-items: flex-start;
    -webkit-box-align: flex-start;
}

.section.position-center {
    justify-content: center;
}

.section.position-left {
    justify-content: flex-start;
}

.section.align-none {
    align-items: unset;
}

.section .col-8 {
    flex: 0 0 70%;
    -webkit-box-flex: 0;
    max-width: 70%;
}

.section .col-7 {
    flex: 0 0 60%;
    -webkit-box-flex: 0;
    max-width: 60%;
}

.section .col-6 {
    flex: 0 0 46.3333%;
    -webkit-box-flex: 0;
    max-width: 46.3333%;
}

.centered {
    text-align: center;
    margin: 0 auto;
}

@media only screen and (max-width: 867px) {
    .section {
        flex-direction: column;
    }

    .section .col-6 {
        flex: 0 0 100%;
        -webkit-box-flex: 0;
        max-width: 100%;
    }

    .section.reverse-col-mobile {
        flex-direction: column-reverse;
    }

    .m-hr {
        width: calc(100% - 40px);
        height: 1px;
        background: #e6e0e0;
        margin: 0 auto;
    }
}
