*,
*:before,
*:after {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

:root {
    --primary-color: #ff7657;
    --secondary-color: #ffebe3;
    --body-fonts: 'Lato', sans-serif;
    --heading-fonts: 'Epilogue', sans-serif;
    --heading-color: #2a2e32;
    --paragraph-size: 17px;
    --paragraph-color: #595959;
    --background-color-light: #f9f7f7;
}

html {
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    scroll-behavior: smooth;
}

body {
    background: var(--background-color-light);
    font: 400 var(--paragraph-size) / 26px var(--body-fonts);
    color: var(--paragraph-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
    font-family: var(--heading-fonts);
    color: var(--heading-color);
    font-weight: 700;
    word-wrap: break-word;
}

ol,
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

h1,
.h1 {
    font-size: 64px;
    line-height: 83px;
}

@media only screen and (max-width: 867px) {
    h1,
    .h1 {
        font-size: 32px;
        line-height: 42px;
    }
}

/* or 42px */
h2,
.h2 {
    font-size: 36px;
    line-height: 47px;
}

@media only screen and (max-width: 867px) {
    h2,
    .h2 {
        font-size: 24px;
        line-height: 31px;
    }
}

h3,
.h3 {
    font-size: 18px;
    line-height: 29px;
}

a {
    text-decoration: none;
    color: inherit;
}

strong,
b {
    font-weight: 800;
}

img {
    max-width: 100%;
    height: auto;
}

.desktop-hidden {
    display: none!important;
}

@media only screen and (max-width: 1067px) {
    .desktop-hidden {
        display: block!important;
    }
}

@media only screen and (max-width: 867px){
    .mobile-hidden {
        display: none !important;
    }
}


.o-center {
    margin: 0 auto;
    display: block;
}