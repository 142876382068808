.premium-domains-images {
    position: relative;
}

.premium-domains-images .link-as-label {
    position: absolute;
    left: 14px;
    top: 9px;
}

.premium-domains-images .link-as-label.metaverseheadsets {
    left: 50%;
}

.premium-domains-images .link-as-label.sportsnft {
    top: 60%;
}

@media only screen and (max-width: 867px) {
    .why-domainer-section {
        margin: 120px 0 -40px;
    }

    .premium-domains-services {
        gap: 23px;
    }
    .premium-domains-services .section {
        flex-direction: row;
    }
}

.services-section .card {
    margin-top: -40px;
}

.premium-domains-section .btn a {
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 5px;
    span {
        padding: 0;
    }
}

@media only screen and (max-width: 867px) {
    .featured-premium-domains-section .grid {
        gap: 15px;
    }

    .featured-premium-domains-section .featured-item {
        border-radius: 63px;
        padding: 16px 0;
    }
}

/* Contact type selection */

.select-contact-option {
    background: #ffffff;
}

.select-contact-option + .select-contact-option {
    margin-top: 24px;
}

.select-contact-option.active {
    background: #ffebe3;
}

.contact-img-row {
    align-items: end;
}

@media only screen and (max-width: 867px) {
    .contact-img-row {
        align-items: baseline;
    }
}

/* Casino pg Page */

/* Old Design pages */

@media only screen and (min-width: 1024px) {
    .intro-body {
        flex-grow: 1;
        padding: 58px 60px;
    }
}

.inner-page h1 {
    font-size: 52px;
}

@media only screen and (max-width: 867px) {
    .inner-page h1 {
        font-size: 28px;
    }
}

.inner-page img {
    max-width: 100%;
    height: auto;
}
.inner-page .intro-body {
    border-radius: 10px;
    position: relative;
}
.inner-page .intro-body:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    background: var(--background-color-light);
}
.inner-page .intro-body .intro-paragraph {
    color: #292929;
    font-weight: 500;
    font-size: 18px;
    width: 90%;
    margin: 35px auto;
}
.inner-page .intro-body p + p {
    margin-top: 20px;
}
.inner-page .intro-body .subheading {
    font-weight: 400;
    font-size: 30px;
    color: #292929;
}
@media only screen and (max-width: 868px) {
    .inner-page .intro-body .subheading {
        font-size: 16px;
        line-height: 28px;
    }
    .inner-page .intro-body .intro-paragraph {
        font-size: 17px;
        line-height: 27px;
        width: 100%;
    }
}
@media only screen and (min-width: 868px) {
    .inner-page .inner-box {
        padding: 0 60px;
    }
}
/* Video */
.video {
    display: block;
    margin: 0 auto;
    padding: 0 60px;
    z-index: 1;
    position: relative;
}
@media only screen and (max-width: 868px) {
    .video {
        padding: 0;
    }
}
.video .youtube {
    border-radius: 10px;
    margin: 30px 0;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    max-width: 100%;
    aspect-ratio: 16 / 9;
    width: 100%;
    background: #292929;
    z-index: 1;
}

.video .youtube img {
    width: 100%;
    left: 0;
    object-fit: cover;
    height: inherit;
}

.video .youtube .play-button {
    width: 98px;
    height: 98px;
    z-index: 1;
    border-radius: 100%;
}

.video .youtube .play-button:before {
    content: '';
    left: 5px;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(/src/image/play_btn.svg);
    background-size: 50px;
    background-position: center center;
    background-repeat: no-repeat;
}

.video .youtube img,
.video .youtube .play-button {
    cursor: pointer;
}

.video .youtube img,
.video .youtube .play-button,
.video .youtube .play-button:before {
    position: absolute;
}

.video .youtube .play-button {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    background-color: #f37d62;
}

.video .youtube iframe {
    height: 100%;
    width: 100%;
}
@media only screen and (max-width: 868px) {
    .video .youtube .play-button {
        width: 62px;
        height: 62px;
    }
    .video .youtube .play-button:before {
        background-size: 30px;
        left: 2px;
    }
}

/* Main Sections */

@media only screen and (min-width: 1025px) {
    .block {
        margin: 70px 0 70px 120px;
    }
    .block .title.left-element:before {
        left: -40px;
        height: 120px;
        width: 25%;
    }
}
.block {
    margin: 50px 0;
}
.orange-box .block {
    margin-top: 0;
}
.block .title {
    position: relative;
}
.block .title:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 15%;
    height: 100%;
    background: #eaf2f8;
    border-radius: 5px;
}
.orange-box .block .title:before {
    background: #f37d62;
}
.block .title.left-element .heading {
    margin: 0;
    width: 100%;
}
.heading {
    font-size: 46px;
    line-height: 64px;
    color: #292929;
    position: relative;
    padding: 30px 0;
    margin: 0 0 0 70px;
    width: 90%;
}
p.heading,
h3.heading {
    font-size: 32px !important;
    line-height: 42px !important;
    margin: 0 !important;
    color: #292929 !important;
    font-weight: 700;
}

/* Star element */

.block .h-with-icon {
    margin: 0 0 30px 70px;
    text-indent: 20px;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 32px;
}
.block .h-with-icon:before {
    content: '';
    position: relative;
    left: 0;
    width: 33px;
    height: 33px;
    top: 0px;
    border-radius: 7px;
}
.block .h-with-icon.blue:before {
    background: #eaf2f8;
}
.block .h-with-icon.star:after {
    content: '';
    position: absolute;
    transform: translate(-50%, -50%);
    -webkit-mask: url(/src/image/star.svg);
    mask: url(/src/image/star.svg);
    -webkit-mask-size: cover;
    mask-size: cover;
    width: 16.5px;
    height: 15.8px;
    top: 17px;
    left: 16px;
}
.block .h-with-icon.blue.star:after {
    background: #4196d3;
}
@media only screen and (max-width: 868px) {
    .block .h-with-icon {
        margin-left: 0;
    }
    .block .h-with-icon:before {
        top: -1px;
    }
    .block .h-with-icon.star:after {
        top: 15px;
    }
}

@media only screen and (max-width: 867px) {
    .block .h-with-icon {
        font-size: 24px;
    }
}

.block .paragraph {
    padding: 30px 40px 0px 70px;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #595959;
}
.block .paragraph p + p {
    margin-top: 20px;
}
.block .paragraph > ul {
    margin: 30px 0;
    list-style-type: disc;
    padding-inline-start: 40px;
}
.block .paragraph > ul > li {
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin: 0px 20px;
}
.block .paragraph > ul > li + li {
    margin-top: 18px;
}
.small {
    font-size: 16px;
    line-height: 22px;
    color: #808080;
}
.orange-component {
    padding: 45px 80px;
    font-weight: 700;
    font-size: 18px;
    line-height: 34px;
    border-radius: 5px;
}
@media only screen and (max-width: 868px) {
    .orange-component {
        padding: 20px 15px;
        line-height: 30px;
    }
}
.section-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 50px;
    margin-top: 70px;
}
.section-item.align-top {
    align-items: baseline;
}
.section-item.stretch {
    align-items: stretch;
}

.section-item .image-wrapper {
    flex: 0 0 50%;
}
.section-item .image-wrapper img {
    max-width: 100%;
    height: auto;
    flex: 1;
}
.section-item.reverse .image-wrapper {
    order: 1;
}
.section-item.col2 .content-wrapper {
    flex-basis: 50%;
}

.section-item .paragraph {
    padding: 0;
}
.mobile {
    display: none;
}
.gap120 {
    gap: 120px;
}

@media only screen and (max-width: 1390px) and (min-width: 869px) {
    .heading {
        font-size: 38px;
        line-height: 46px;
    }
}
@media only screen and (max-width: 868px) {
    .inner-page .intro-body {
        width: 94%;
        text-align: center;
        padding-top: 40px;
    }
    .inner-page .intro-body:after {
        height: 20%;
    }
    .inner-page .intro-body .paragraph {
        width: 100%;
        margin: 26px auto;
    }
    .orange-box .block {
        margin-top: 50px;
    }
    .block {
        margin-left: 0;
    }
    .block .title:before {
        width: 98px;
        height: 140%;
        margin-left: -20px;
        top: 50%;
        transform: translateY(-50%);
    }
    .heading {
        font-size: 28px;
        line-height: 36px;
        margin-left: 0px;
        margin-bottom: 25px !important;
        width: 100%;
        padding: 0;
    }
    p.heading,
    h3.heading {
        font-size: 24px !important;
        line-height: 30px !important;
    }
    .block .paragraph {
        padding: 0;
    }
    .block .paragraph > ul > li {
        font-size: 18px;
        line-height: 22px;
    }
    #best .block {
        margin: 0;
    }
    .section-item {
        flex-direction: column;
        gap: 20px;
    }
    .section-item.reverse .image-wrapper {
        order: 0;
    }
    .mobile {
        display: block;
    }
    .desctop {
        display: none;
    }
    .heading.mobile-hidden {
        display: none;
    }
}

/* Bacground Image */
.img-bg {
    width: 100%;
    height: 380px;
    border-radius: 10px;
    background: url(/src/image/bg_domain_dressing.webp);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
@media only screen and (max-width: 1024px) {
    .img-bg {
        height: 300px !important;
    }
}
@media only screen and (max-width: 868px) {
    .img-bg {
        height: 260px !important;
    }
}
@media only screen and (max-width: 668px) {
    .img-bg {
        height: 220px !important;
    }
}
@media only screen and (max-width: 430px) {
    .img-bg {
        height: 192px !important;
    }
}

/* Services */

.services-listing .service {
    border: 1px solid #000000;
    border-left-width: 14px;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 36px 66px;
}
.services-listing .service + .service {
    margin-top: 30px;
}
.services-listing .service .heading {
    font-family: 'Epilogue';
    font-size: 24px;
    line-height: 30px;
    padding: 0 0 20px;
    position: relative;
    display: flex;
    align-items: center;
    margin: 0;
}
.services-listing .service .heading:before {
    position: relative;
    margin-right: 10px;
}
.services-listing .service#content .heading:before {
    content: url(/src/image/content_icon.svg);
}
.services-listing .service#design .heading:before {
    content: url(/src/image/design_icon.svg);
}
.services-listing .service#seo .heading:before {
    content: url(/src/image/seo_icon.svg);
}
.services-listing .service p {
    font-size: 16px;
    line-height: 30px;
    color: #595959;
}
@media only screen and (max-width: 868px) {
    .services-listing .service {
        padding: 36px;
    }
}

/* Compare section */
.compare {
    display: flex;
    position: relative;
    padding-bottom: 40px;
}
.compare .paragraph {
    border-radius: 5px;
    padding: 40px 50px;
}
.compare h3 {
    color: #292929;
    margin-bottom: 30px;
    font-size: 32px;
}

@media only screen and (min-width: 1400px) {
    .compare-img {
        right: 110px !important;
    }
}
@media only screen and (min-width: 1024px) {
    .compare .paragraph {
        width: 60%;
    }
    .compare-img {
        position: absolute;
        right: 0px;
        top: 70px;
    }
    .compare .widht70 {
        width: 80%;
        margin: inherit;
    }
}

@media only screen and (max-width: 867px) {
    .compare {
        padding-bottom: 0;
    }

    .compare h3 {
        font-size: 24px;
    }
}

@media only screen and (max-width: 1024px) {
    .compare {
        flex-direction: column;
        text-align: center;
    }
    .compare .wdht-80 {
        width: 100%;
    }
    .compare .paragraph {
        padding: 30px 20px;
    }
    .compare .mobile-hidden {
        display: none !important;
    }
}

/* Profit Section */

.profit h3 {
    font-size: 32px;
}

.profit .box {
    border-radius: 5px;
    padding: 0 44px;
}
.profit .heading {
    font-size: 22px;
    line-height: 28px;
    margin: 0;
    width: 100%;
}
.profit .box .paragraph {
    color: #595959;
}
@media only screen and (max-width: 868px) {
    .profit .section-item {
        margin-top: 30px;
    }
    .profit .box {
        padding: 0 20px;
    }
}

@media only screen and (max-width: 867px) {
    .profit h3 {
        font-size: 24px;
    }
}

/* Casino jp Page */

@media only screen and (max-width: 868px) {
    .casino-jp-logo {
        max-width: 164px;
        margin-bottom: 20px;
    }
    .casino-jp-page .intro-paragraph {
        color: #524843;
        font-size: 18px;
        font-weight: 700;
        line-height: 26px;
        margin-top: 9px;
    }
}

.text-work-with {
    color: #2a2e32;
    font-size: 30px;
    font-weight: 400;
    position: relative;
    left: 74px;
    top: 10px;
}

@media only screen and (max-width: 868px) {
    .text-work-with {
        font-size: 26px;
    }
}

.casino-jp-page .card .title {
    font-size: 20px;
}

.casino-jp-page .card .text {
    font-size: 18px;
}

.domain-links-title {
    font-size: 44px;
    line-height: 57px;
}

.home .domain-link-wrapper {
    position: relative;
    top: 50px;
    z-index: 1;
    padding-left: 9px;
    padding-right: 9px;
    width: 80%;
    cursor: auto;
}

.domain-links-wrapper {
    margin: 26px 0;
}

.domain-link-wrapper {
    border-radius: 82px;
    padding: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.domain-link-text {
    padding: 0 40px 0 20px;
    font-size: 24px;
}

.domain-link-img {
    border-radius: 82px;
    padding: 14px;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 868px) {
    .domain-links-title {
        font-size: 30px;
        line-height: 39px;
    }

    .domain-link-text {
        margin: 0 auto;
    }

    .home .domain-link-wrapper {
        top: 0;
        margin-bottom: 46px;
        border-radius: 20px;
        padding: 0 10px 27px 10px;
        gap: 33px;
        width: 100%;
        .btn {
            width: 70%;
        }
    }

    .home .domain-link-img {
        position: relative;
        top: -30px;
    }
}

.quote-img {
    position: absolute;
    bottom: 0;
    left: 0;
}

.quote-wrapper {
    padding: 0 30px;
    color: #2a2e32;
    font-size: 22px;
    line-height: 36px;
}

@media only screen and (max-width: 1240px) {
    .quote-img {
        width: 360px;
    }
}

@media only screen and (max-width: 868px) {
    .quote-img-wrapper {
        height: 200px;
    }

    .quote-img {
        width: 301px;
    }

    .quote-wrapper {
        padding: 0;
        font-size: 18px;
        line-height: 24px;
    }
}

.calendar-popup .popup {
    height: 90%;
    margin: 2% auto;
}

.calendar-popup.popup-visible .icon {
    background: white;
    border-radius: 5px;
    height: 40px;
    width: 40px;
    right: 10px;
    top: 10px;
}
.calendar-popup.popup-visible .icon:before {
    bottom: 20px;
    right: 7px;
}
.calendar-popup.popup-visible .icon:after {
    top: 18px;
    right: 8px;
}

@media only screen and (max-width: 868px) {
    .calendar-popup .popup {
        height: 100%;
        margin: 0 auto;
    }
}
