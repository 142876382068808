.grid {
    display: grid;
    overflow-wrap: anywhere;
}

.grid.col-2 {
    grid-template-columns: 1fr 1fr;
}

.grid.col-2-1-1 {
    grid-template-columns: 1.5fr 1fr 1fr;
}

.grid.col-2-1 {
    grid-template-columns: 2fr 1fr;
}

.grid.col-1-2 {
    grid-template-columns: 1fr 2fr;
}

.grid.col-3 {
    grid-template-columns: 1fr 1fr 1fr;
}

.grid.col-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}



@media only screen and (max-width: 867px) {
    .grid.col-2,
    .grid.col-2-1-1,
    .grid.col-1-2,
    .grid.col-3,
    .grid.col-4 {
        grid-template-columns: 1fr;
    }
    .m-reverse{
        order: 2;
    }
}
