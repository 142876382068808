/* Numbered list */

.numbered-list {
    counter-reset: list-number;
}

.numbered-list li{
    display: flex;
    align-items: center;
    gap: 10px
}

.numbered-list li+li{
    margin-top: 70px;
}

.numbered-list li:before {
    counter-increment: list-number;
    content: counter(list-number) '. ';
    margin-right: 10px;
    width: 35px;
    height: 35px;
    display: inline-flex;
    align-items: end;
    justify-content: center;
    font-family: 'Epilogue';
    font-weight: 700;
    font-size: 48px;
    color: #6588a1;
}
