@font-face {
    font-family: Lato;
    src: url(/src/fonts/Lato-Regular.ttf);
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: Lato;
    src: url(/src/fonts/Lato-Italic.ttf);
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: Lato;
    src: url(/src/fonts/Lato-Bold.ttf);
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: Lato;
    src: url(/src/fonts/Lato-Black.ttf);
    font-weight: 900;
    font-display: swap;
}

@font-face {
    font-family: Lato;
    src: url(/src/fonts/Lato-BlackItalic.ttf);
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: Epilogue;
    src: url(/src/fonts/Epilogue-Bold.ttf);
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: Epilogue;
    src: url(/src/fonts/Epilogue-BoldItalic.ttf);
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: Epilogue;
    src: url(/src/fonts/Epilogue-Black.ttf);
    font-weight: 900;
    font-display: swap;
}
