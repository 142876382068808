.hint {
    background: var(--background-color-light);
    border-radius: 50px;
    padding: 19px 25px;
    gap: 20px;
    max-width: 420px;
    width: 100%;
    position: relative;
    top: 120px;
    left: -60px;
    z-index: 1;
}

.hint-title {
    font-style: italic;
    color: #ababab;
}

.hint-desc {
    font-style: italic;
    font-weight: 700;
    line-height: 20px;
    color: var(--heading-color);
}

.hint-desc span {
    color: #6588a1;
}

@media only screen and (max-width: 867px) {
    .hint {
        flex-direction: row;
        display: flex;
        background: #ffffff;
        left: 10px;
    }
}
