.header .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.site-header {
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 20;
}

.site-nav .nav-logo,
.site-nav .social {
    display: none;
}

.links {
    position: relative;
    display: flex;
    align-items: center;
    gap: 76px;
}

.links .menu-link {
    color: var(--heading-color);
    font-weight: 700;
    text-decoration: none;
    transition: all 0.25s ease-in-out;
}

@media only screen and (max-width: 1067px) {
    .header {
        padding: 20px 0;
    }

    .site-header .site-nav {
        position: unset;
        z-index: auto;
    }

    .nav-visible .site-nav .nav-logo .logo,
    .nav-visible .site-nav .social,
    .site-nav .nav-logo {
        display: block;
    }

    .nav-visible .site-nav .nav-logo .logo {
        background-image: url(/src/image/logo-domainer-desktop.svg);
    }

    .site-nav .nav-logo .logo {
        display: none;
    }

    .site-nav .icon-box > * {
        pointer-events: none;
    }

    .site-nav .icon-box {
        background: transparent;
        border-radius: 10px;
        height: 60px;
        position: absolute;
        right: 20px;
        top: -10px;
        width: 60px;
        cursor: pointer;
    }

    .site-nav .icon {
        border-bottom: 3px solid var(--heading-color);
        box-sizing: border-box;
        cursor: pointer;
        height: 27px;
        left: 19px;
        right: 13px;
        top: 16px;
        width: 27px;
        z-index: 30;
    }

    .site-nav .icon,
    .site-nav .icon:before {
        position: absolute;
        -webkit-transition: all 0.3s ease-in;
        -moz-transition: all 0.3s ease-in;
        -khtml-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in;
        transition: all 0.3s ease-in;
    }

    .site-nav .icon:before {
        top: 0;
        width: 16px;
    }

    .site-nav .icon:after,
    .site-nav .icon:before {
        background: var(--heading-color);
        content: '';
        display: block;
        height: 3px;
        right: 0;
    }

    .site-nav .icon:after {
        bottom: 9px;
        position: absolute;
        -webkit-transition: all 0.3s ease-in;
        -moz-transition: all 0.3s ease-in;
        -khtml-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in;
        transition: all 0.3s ease-in;
        width: 34px;
    }

    .nav-visible .site-nav .icon-box {
        top: 10px;
    }

    .nav-visible .site-nav .icon {
        border: transparent;
    }

    .nav-visible .site-nav .icon:before {
        right: -1px;
        top: 12px;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -khtml-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        width: 32px;
    }

    .nav-visible .site-nav .icon:after {
        right: 0;
        top: 12px;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -khtml-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
        width: 32px;
    }

    .nav-visible .site-nav {
        background: rgba(41, 41, 41, 0.98);
        bottom: 0;
        height: 100%;
        left: 0;
        overflow-x: hidden;
        overflow-y: auto;
        position: fixed;
        text-align: left;
        top: 0;
        width: 100%;
        z-index: 5;
    }

    .nav-visible .nav-logo {
        background: #fff;
        padding: 20px 0;
    }

    .site-header.nav-visible .container {
        display: flow-root;
    }

    .site-nav > ul {
        display: none;
        opacity: 0;
        visibility: hidden;
    }

    .nav-visible .site-nav > ul {
        align-items: center;
        display: grid;
        margin: 30px 0 30px 20px;
        gap: 0;
        opacity: 1;
        position: relative;
        visibility: visible;
    }

    .nav-visible .site-nav > ul[data-title]:before {
        color: #c8c8c8;
        content: attr(data-title);
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
        position: relative;
        text-transform: uppercase;
        top: 0;
    }

    .nav-visible .site-nav > ul.social[data-title]:before {
        left: 0;
        width: 100%;
    }

    .nav-visible .site-nav > ul > li > a:after {
        content: none;
    }

    .nav-visible .site-nav > ul:after {
        background: #ff6c4a;
        bottom: 180px;
        content: '';
        height: 2px;
        left: 20px;
        position: absolute;
        top: 30px;
        width: 10%;
    }

    .nav-visible .site-nav > ul > li {
        align-items: baseline;
        display: flex;
        margin: 20px 0 20px 20px;
        padding: 0;
        position: relative;
    }

    .nav-visible .site-nav > ul.links > li:before {
        content: url(/src/image/arrow_right.svg);
        margin-right: 15px;
    }

    .nav-visible .site-nav .menu-link {
        color: #fff;
        font-size: 17px;
        font-weight: 700;
        line-height: 28px;
        text-decoration: none;
    }

    .nav-visible .site-nav > ul.social {
        flex-wrap: wrap !important;
        justify-content: flex-start !important;
        margin-left: 20px !important;
        padding-left: 15px !important;
    }
}

/* Dropdown */

.dropdown {
    padding: 35px 0;
    position: relative;
}

.dropbtn > * {
    pointer-events: none;
}
.dropdown-content {
    opacity: 0;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.35s ease-out;
}
.dropdown-content a {
    font-weight: 500;
    font-size: 15px;
    line-height: 28px;
    color: #cacaca;
    text-decoration: none;
    transition: color 0.25s;
    display: block;
    padding: 10px 0;
    position: relative;
}

.dropdown-content a:hover,
.links .menu-link:hover {
    color: #ff6c4a;
    text-decoration: none;
}
.show .dropdown-content {
    max-height: 500px;
    transition: max-height 0.35s ease-in, opacity 0.25s;
    opacity: 1;
}
.dropbtn {
    cursor: pointer;
    position: relative;
    padding-right: 15px;
    display: inline-block;
}

.dropbtn:before,
.dropbtn:after {
    content: '';
    margin: 0 0 -8px;
    position: absolute;
    right: 0;
    top: 50%;
    background: #f4866d;
}

.dropbtn:before {
    height: 8px;
    margin-top: -2px;
    right: 4px;
    width: 2px;
    transition: all 0.35s;
}
.dropbtn:after {
    height: 2px;
    margin-top: 1.1px;
    right: 1px;
    width: 8px;
}

@media only screen and (min-width: 1067px) {
    .dropdown:hover .dropbtn:before {
        height: 0;
        margin-top: 0;
    }
    .dropdown:hover .dropdown-content {
        height: auto;
        max-height: 500px;
        opacity: 1;
        overflow: visible;
    }
    .dropdown-content {
        height: 0;
        opacity: 0;
        overflow: hidden;
        transition: all 0.25s ease-in-out;
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        top: 80px;
        z-index: 1;
        width: 276px;
        background: rgba(255, 255, 255, 0.9);
        border: 1px solid #ffebe3;
        -webkit-border-radius: 19px;
        -moz-border-radius: 19px;
        border-radius: 19px;
        padding: 0 15px;
    }

    .dropdown-content::before {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        width: 14px;
        height: 14px;
        border-radius: 3px;
        border-left: 1px solid #ffebe3;
        border-top: 1px solid #ffebe3;
        background: #ffffff;
        transform: rotate(45deg) translateX(-50%);
        z-index: 1;
    }

    .dropdown-content::after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        z-index: 1;
        width: 28px;
        height: 3px;
        background: #ffffff;
    }

    .dropdown-content a {
        color: #2a2e32;
        font-weight: 700;
        text-align: center;
        font-size: 16px;
    }
}

@media only screen and (max-width: 1067px) {
    .dropdown-content a:before {
        content: url(/src/image/drop-menu-icon.svg);
        margin-right: 0.8em;
    }
}
