.tab {
    cursor: pointer;
}

.mytabs {
    display: none;
}
.mytabs.active {
    display: block;
}
