.footer .title {
    font-family: 'Epilogue';
    font-weight: 700;
    font-size: 15px;
    line-height: 28px;
    color: #e7e7e7;
    margin: 18px 0;
    position: relative;
}

.footer .title::after {
    content: '';
    background: #ff6c4a;
    display: block;
    height: 1px;
    width: 62px;
}

.footer .generic {
    color: #9a9a9a;
    font-size: 15px;
    line-height: 28px;
    cursor: pointer;
}

.footer .generic ul li + li:before {
    content: '| ';
    margin: 0 15px;
}

.footer .footer-nav a {
    font-family: 'Lato';
    font-weight: 500;
    font-size: 15px;
    line-height: 36px;
    color: #ffffff;
    transition: color 0.25s;
}

.footer .footer-nav a:hover {
    color: var(--primary-color);
}

.footer .socials .section {
    gap: 20px;
}

.footer .mob-visible.generic {
    display: none;
}

@media only screen and (max-width: 867px) {
    .footer .socials .section {
        flex-direction: row;
        flex-wrap: wrap;
    }
    .footer .generic {
        display: none;
    }

    .footer .generic .section {
        display: flex;
        justify-content: center;
        flex-direction: row;
        color: #e7e7e7;
    }

    .footer .mob-visible.generic {
        display: block;
        margin-top: 20px;
    }
}
