/* Privacy and Terms of use */

.privacy-policy,
.terms-of-use,
.subscription-thank-you,
.risk-disclaimer,
.calendar-popup {
    transition: opacity 0.3s ease-out;
    opacity: 0;
    height: 0;
    overflow: hidden;
}
.popup-visible {
    opacity: 1;
    height: auto;
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    height: 100%;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    text-align: left;
    top: 0;
    width: 100%;
    z-index: 99999999;
}
.popup-visible .popup {
    height: 75%;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    max-width: 1149px;
    z-index: 5;
    border-radius: 10px;
    background: white;
    margin: 5% auto;
    left: 0;
    right: 0;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .popup-visible .popup {
        height: 80%;
    }
}
@media only screen and (min-width: 620px) and (max-width: 914px) {
    .popup-visible .popup .inner {
        height: 42% !important;
    }
}

.popup-visible .popup .container {
    background-color: white;
    border-radius: 10px;
}
.popup-visible .popup .title {
    font-family: Playfair Display;
    font-size: 42px;
    line-height: 49px;
    text-align: center;
    color: #292929;
    padding: 40px 0 0px;
    font-weight: 700;
    display: block;
    margin-bottom: 20px;
}
.popup-visible .popup h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 20px;
    padding: 20px 0;
}
.popup-visible .popup h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    padding: 20px 0px;
}

.popup-visible .popup p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}
.popup-visible .popup ul {
    margin-left: 20px;
}
@media only screen and (max-width: 867px) {
    .popup-visible .popup .title {
        font-size: 24px;
        line-height: 28px;
    }
    .popup-visible .popup h2 {
        font-size: 18px;
        line-height: 20px;
    }
    .popup-visible .popup h3 {
        font-size: 14px;
        line-height: 20px;
    }
    .popup-visible .popup p {
        font-size: 14px;
        line-height: 20px;
    }
}
.popup-visible .popup .inner {
    background: #eaf2f8;
    border-radius: 10px;
    max-width: 1120px;
    padding: 15px;
    margin: 15px;
    height: 55%;
    overflow: auto;
    position: fixed;
}
@media only screen and (min-width: 1700px) {
    .popup-visible .popup .inner {
        height: 58%;
    }
}
.popup-visible .icon {
    box-sizing: border-box;
    cursor: pointer;
    height: 25px;
    position: absolute;
    right: 20px;
    top: 25px;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -khtml-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    width: 30px;
    z-index: 30;
}
.popup-visible .icon:after,
.popup-visible .icon:before {
    background: #000;
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    right: 0;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -khtml-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    width: 30px;
}
.popup-visible .icon:after {
    right: -5px;
    top: 0px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -khtml-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
    width: 26px;
}
.popup-visible .icon:before {
    right: -6px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -khtml-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 26px;
}
.popup-visible .icon:after {
    bottom: 0;
}

.popup-subscription .subs-thx-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.popup-subscription {
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    z-index: 5;
    border-radius: 10px;
    background: white;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 0;
    height: 0;
}
.popup-visible .popup-subscription {
    max-width: 40%;
    width: auto;
    height: 50%;
}
.popup-visible .logo {
    background: url(/src/image/group.svg) center center / contain no-repeat;
    box-sizing: border-box;
    cursor: pointer;
    height: 25px;
    position: absolute;
    left: 20px;
    top: 12px;
    width: 30px;
    z-index: 30;
}
.popup-subscription .title {
    font-family: Playfair Display;
    font-size: 42px;
    text-align: center;
    color: #292929;
    padding: 15px 0 0px;
    font-weight: 700;
    display: block;
    margin-bottom: 180px;
}
.popup-subscription .title:hover {
    text-decoration: none;
}
.popup-subscription .title:after {
    content: '';
    position: absolute;
    width: 50%;
    transform: translateX(-50%) scaleX(0);
    height: 3px;
    left: 50%;
    border-radius: 10px;
    background-color: #ff6c4a;
    transition: transform 0.25s ease-out;
    margin-top: 50px;
}
.popup-subscription .title:hover:after {
    transform: translateX(-50%) scaleX(1);
}
.popup-subscription .ok {
    background: url(/src/image/ok.svg) center center / contain no-repeat;
    width: 106px;
    height: 106px;
    margin-top: 100px;
}
.popup-subscription .info {
    font-size: 14px;
    line-height: 22px;
}
