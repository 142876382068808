.field,
.select,
.textarea {
    background: transparent;
    border: solid #1a1a1a;
    border-radius: 0;
    border-width: 0 0 1px;
    box-shadow: none;
    color: var(--heading-color);
    display: block;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    line-height: 26px;
    width: 100%;
}

.field,
.select {
    height: 43px;
}

.field:focus,
.select:focus,
.textarea:focus {
    border-bottom: solid #ff7657;
}

.select {
    cursor: pointer;
    overflow: hidden;
    position: relative;
}

.select:required:invalid {
    color: #292929;
    display: block;
    line-height: 42px;
}

.select:after {
    border-color: #000;
    border-style: solid;
    border-width: 0 1px 1px 0;
    content: '';
    height: 8px;
    margin: -5px 0 0;
    pointer-events: none;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: rotate(45deg);
    width: 8px;
}

.select.filled:before {
    color: #1a1a1a;
}

.select select {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    box-shadow: none;
    color: transparent;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}

.select option {
    color: #1a1a1a;
}

.textarea {
    position: relative;
    resize: none;
}

.textarea::placeholder {
    bottom: 20px;
    position: absolute;
}

.field::placeholder,
.textarea::placeholder {
    color: #292929;
    opacity: 1;
}

.contact-form {
    width: 100%;
}

.contact-form .row,
.newsletter-form .row {
    align-items: flex-end;
    display: flex;
    flex-basis: 100%;
    flex-flow: row;
    gap: 40px;
    justify-content: space-between;
    overflow: hidden;
    width: 100%;
}

.contact-form .row:not(:last-child) {
    margin-bottom: 46px;
}

.contact-btn-box {
    flex: 1;
}

.contact-btn-box .btn {
    display: block;
    font-size: 17px;
    height: 62px;
    margin-left: 2px;
    width: 230px;
}

@media only screen and (max-width: 867px) {
    .contact-form .row {
        flex-direction: column;
    }

    .field::placeholder,
    .textarea::placeholder {
        font-size: 16px;
        line-height: 28px;
    }

    .contact-btn-box {
        margin: 20px auto 0;
    }
}

.loader {
    display: none;
}

.loader.show {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 53px;
}

.loader .wrapper {
    display: inline-block;
    position: relative;
    width: 14px;
    height: 14px;
}

.loader span {
    margin-left: 8px;
    color: white;
}

.loader .wrapper div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    animation: rotation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border: 8px solid black;
    border-width: 2px;
    border-color: white transparent transparent transparent;
}

.loader .wrapper div:nth-child(1) {
    animation-delay: -0.45s;
}

.loader .wrapper div:nth-child(2) {
    animation-delay: -0.3s;
}

.loader .wrapper div:nth-child(3) {
    animation-delay: -0.15s;
}
.success-message,
.error-message {
    text-align: center;

    a {
        color: var(--color-highlights-green);
    }
}

.hidden {
    display: none !important;
}

/* Newsletter */

.newsletter-form .row {
    gap: 0;
}

.newsletter-form .field {
    border-bottom: 1px solid #fff;
    color: #fff;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    text-align: left;
}

.newsletter-form .field::placeholder {
    color: #ffffff;
    font-size: 15px;
    line-height: 28px;
}

.newsletter-form .btn {
    background: transparent;
    border: 0;
    border-bottom: 1px solid #fff;
    border-radius: 0;
    color: var(--primary-color);
    cursor: pointer;
    display: block;
    font-size: 15px;
    font-weight: 600;
    height: 57px;
    line-height: 57px;
    padding-right: 18px;
}

.newsletter-form .newsletter-btn {
    position: relative;
}

.newsletter-form .newsletter-btn:after {
    background: url(/src/image/arrow.svg) 50% / contain no-repeat;
    bottom: 24px;
    content: '';
    height: 8px;
    margin-top: -3px;
    position: absolute;
    right: 0;
    width: 9px;
}

.subscribe-form .error-message,
.subscribe-form .success-message,
.subscribe-form p {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #9a9a9a;
    margin: 20px 0 0;
}

.subscribe-form p a {
    color: inherit;
    text-decoration: underline;
}

.subscribe-form p a:hover {
    text-decoration: none;
}

.subscribe-btn {
    margin-right: 0;
    padding-right: 24px;
}

@media only screen and (max-width: 867px) {
    .newsletter-form .btn {
        border: none;
        padding-left: 0;
    }

    .field::placeholder {
        font-size: 15px;
        line-height: 28px;
    }
}
