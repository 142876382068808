.domainer-blog-section {
    padding: 67px 0;
}

.blog-wrapper {
    position: relative;
}

.categories-wrapper {
    margin: 36px 0;
    padding-right: 40px;
}

.blog-category {
    border-radius: 38px;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    position: relative;
    background: #fff8f5;
    border: 1px solid #fff8f5;
    transition: all 0.25s;
    font-weight: 700;
    font-size: 16px;
}
.blog-category:hover {
    border: 1px solid #292929;
}

.blog-category:before {
    content: '';
    width: 4px;
    height: 4px;
    background: #6588a1;
    margin-right: 13px;
    display: block;
    position: relative;
}

.blog-category::after,
.post-title::after {
    content: url(/src/image/arrow_right.svg);
    font-size: inherit;
    position: absolute;
    transition: all 0.25s;
    z-index: -1;
    right: 30px;
}

.blog-category:hover::after,
.post-title:hover::after {
    filter: brightness(0%);
    right: 20px;
    z-index: 1;
}

.articles-wrapper {
    z-index: 1;
    position: relative;
    padding: 56px 0;
}

.article + .article {
    margin-top: 52px;
}
.post-image {
    border-radius: 20px;
    object-fit: cover;
    height: 176px;
    width: 100%;
}

.post-title {
    font-family: 'Epilogue';
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    position: relative;
}

.post-title::after {
    content: '';
    position: relative;
    right: 0;
}

.post-title:hover::after {
    content: url(/src/image/arrow_right.svg);
    right: -10px;
}

.post-timestamp {
    color: #7d7d7d;
}

@media only screen and (max-width: 867px) {
    .blog-wrapper .section {
        gap: 10px;
    }
    .categories-wrapper {
        padding-right: 0px;
    }
    .articles-wrapper {
        padding: 18px 0;
    }
    .article {
        padding: 0 20px;
    }
    .post-image {
        height: 183px;
    }
}
